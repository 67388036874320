<template>
  <div class="fill-height d-flex align-center justify-center">
    <div class="d-flex flex-column justify-center">
      <v-icon class="mx-auto mb-3" x-large>mdi-wifi-off</v-icon>
      <h3 class="mb-5">Sin conexión a Internet</h3>
      <v-btn
        class="text-capitalize mx-auto"
        color="primary"
        @click="redirectToBack"
        ><v-icon class="mr-2">mdi-autorenew</v-icon> Reintentar</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Offline",
  methods: {
    redirectToBack() {
      if (this.isOnline) {
        if (this.prevRoute) {
          this.$router.push({ path: this.prevRoute });
          return;
        }
        this.$router.go(-1);
      }
    },
  },
  computed: {
    isOnline() {
      return this.$store.getters["offline/isOnline"];
    },
    prevRoute() {
      return this.$store.getters["offline/prevRoute"];
    },
  },
};
</script>
